import React, { useEffect, useState } from "react";

import Layout from "../components/Layout";

import * as S from "./blog-list/styled";

const BlogList = (props) => {
  return (
    <Layout>
      <h1>Blog List</h1>
    </Layout>
  );
};

export default BlogList;